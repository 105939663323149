export default {
    getAvailableApps: state => {
        if (state.orgId.toString() !== '1') {
            return state.availableApps
        }
        return []
    },
    getApp: state => state.app,
    getOrgId: state => state.orgId,
    getIsEditable: state => state.isEditable,
    getAlarmPlan: state => state.alarmPlan,
    getAlarmPlans: state => state.alarmPlans.list,
}
